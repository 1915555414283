import React from 'react'
import {
  CFSelect,
  CFView,
  CFImage,
  DefaultScreen,
  MobileScreen,
} from 'components'
import { downloadButton, menu } from 'images'
import { dispatch } from 'store'

export default () => {
  return (
    <CFSelect selector={dispatch.restaurant.getPdfMenuUrl}>
      {pdfMenuUrl => (
        <CFView>
          <MobileScreen>
            <a href={pdfMenuUrl} target="_blank">
              <CFView hover>
                <CFImage
                  src={downloadButton}
                  w="100%"
                  maxWidth="370px"
                  alt="About"
                />
              </CFView>
            </a>
          </MobileScreen>
          <DefaultScreen>
            <CFView hover>
              <a href={pdfMenuUrl} target="_blank">
                <CFImage src={downloadButton} maxWidth="280px" alt="About" />
              </a>
            </CFView>
          </DefaultScreen>
        </CFView>
      )}
    </CFSelect>
  )
}
