import React from 'react'
import {
  CFView,
  CFText,
  CFImage,
  CFLink,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import OrderDeliveryButton from './OrderDeliveryButton'
import { hero, logo, mobileHero, viewMenu, menu } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView column center>
          <CFImage src={logo} alt="Logo" w="330px" mv="5px" />
          <CFImage src={mobileHero} alt="Logo" w="90%" mv="10px" />
          <CFView column center w="80%">
            <CFView mt="10px" pulsate>
              <OrderPickupButton />
            </CFView>
            <CFView mt="10px">
              <OrderDeliveryButton />
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView w="100%" zIndex={90} column justifyBetween alignCenter>
          <CFImage src={logo} alt="Logo" w="370px" mb="10px" mt="5px" />
          <CFImage src={hero} alt="Logo" w="90%" />
          <CFView
            row
            center
            raleway
            xBold
            white
            style={{ fontSize: 34 }}
            bg="rgba(0,0,0,.3)"
            w="90.3%"
            pv="8px"
          >
            {/* <CFText>NOW TAKING ONLINE ORDERS</CFText> */}
            <CFView ml="30px" pulsate mt="4px">
              <OrderPickupButton />
            </CFView>
            <CFView ml="30px" mt="4px">
              <OrderDeliveryButton />
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
